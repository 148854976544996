import styled from 'styled-components';
import userConfig from '../../../config'

const P = styled.p`
  color: ${userConfig.primaryColor};
  display: block;
  font-size: 1.6em;
  margin: 50px 0 0 0;
  text-align: center;
`;

export default P;