import styled from "styled-components";
import { primaryColor, secondaryColor } from "../../../config";
import { lighten } from "polished";

const SocialLink = styled.a`
  border: 1px solid ${lighten(0.5, primaryColor)};
  border-radius: 15px;
  color: ${primaryColor};
  display: inline-block;
  margin: 5px;
  padding: 5px 10px;
  text-decoration: none;

  svg {
    display: inline-block;
    height: 20px;
    margin-right: 5px;
    fill: ${primaryColor};
    vertical-align: middle;
    width: auto;
  }

  &:hover {
    border-color: ${primaryColor};
  }
`;

export default SocialLink;
