import styled from 'styled-components';
import userConfig from '../../../config';
import { media } from '../../utils/media';

const H1 = styled.h1`
  color: ${userConfig.primaryColor};
  font-size: 35px;
  line-height: 1.5;
  margin: 0;
  padding: 0 30px;
  text-align: center;
`;



export default H1;