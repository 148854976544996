import styled from 'styled-components';
import userConfig from '../../../config';
import { media } from '../../utils/media';
import { lighten } from 'polished';


const RoundBoarderWrapper = styled.div`
  position: relative;
  background-color: ${lighten(0.3, userConfig.primaryColor)};
  border: 5px solid ${lighten(0.3, userConfig.primaryColor)};
  border-radius: 50%;
  margin: 0 15px;
  overflow: hidden;  
  width: 150px;
  height: 150px;
  @media ${media.phone} {
    width: 100px;
    height: 100px;
    border: 3px solid ${lighten(0.3, userConfig.primaryColor)};
  }

  img {
    padding: 0;
    border-radius: 50%;
  }

  &::after {
    border-radius: 50%;
    box-shadow: inset 0px 0px 3px 1.5px ${userConfig.primaryColor};
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
`;

export default RoundBoarderWrapper;