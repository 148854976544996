import React from "react";
import Unselectable from "./Unselectable";
import styled from "styled-components";
import { lighten } from "polished";
import userConfig from "../../../config";
import { media } from "../../utils/media";

const Left = styled.div`
  display: inline-block;
  flex: 1;
  & > * {
    float: right;
  }
`;

const Right = styled.div`
  display: inline-block;
  flex: 1;
  & > * {
    float: left;
  }
`;

const Center = styled.div`
  display: inline-block;
`;

const StyledSpan = styled.span`
  color: ${lighten(0.3, userConfig.primaryColor)};
  font-size: 100px;
  @media ${media.phone} {
    font-size: 70px;
  }
`;

const Container = styled(Unselectable)`
  display: flex;
  align-items: center;

  &:hover ${Left} {
    & > span {
      animation-name: go-left;
      animation-duration: 0.75s;
      animation-fill-mode: forwards;
    }
  }

  &:hover ${Right} {
    & > span {
      animation-name: go-right;
      animation-duration: 0.75s;
      animation-fill-mode: forwards;
    }
  }

  @keyframes go-left {
    0% {
      padding-right: 0;
    }
    50% {
      padding-right: 15px;
    }
    100% {
      padding-right: 0;
    }
  }

  @keyframes go-right {
    0% {
      padding-left: 0;
    }
    50% {
      padding-left: 15px;
    }
    100% {
      padding-left: 0;
    }
  }
`;

function CodeStyleWrapper(props) {
  const { children, ...otherProps } = props;
  return (
    <Container {...otherProps}>
      <Left>
        <StyledSpan>&lt;</StyledSpan>
      </Left>
      <Center>{children}</Center>
      <Right>
        <StyledSpan>&gt;</StyledSpan>
      </Right>
    </Container>
  );
}

export default CodeStyleWrapper;
