import React from "react";
import Link from "gatsby-link";
import RoundBoarderWrapper from "./RoundBoarderWrapper";
import CodeStyleWrapper from "./CodeStyleWrapper";
import styled from "styled-components";
import { media } from "../../utils/media";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const HeaderLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
`;

const Header = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        portrait: file(relativePath: { eq: "portrait.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );

  return (
    <CodeStyleWrapper {...props}>
      <RoundBoarderWrapper>
        <Img fluid={data.portrait.childImageSharp.fluid} />
      </RoundBoarderWrapper>
    </CodeStyleWrapper>
  );
};

const HeaderWithBottomSpacing = styled(Header)`
  margin-bottom: 15px;
  @media ${media.phone} {
    margin-bottom: 10px;
  }
`;

function HeaderImage() {
  return (
    <HeaderLink to="/">
      <HeaderWithBottomSpacing />
    </HeaderLink>
  );
}

export default HeaderImage;
